<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="用户名" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入用户名称" v-trim/>
      </el-form-item>
      <el-form-item label="PC_COOKIE" prop="pcCookie" required>
        <el-input v-model="form.pcCookie" placeholder="请输入PC_COOKIE" v-trim/>
      </el-form-item>
      <el-form-item label="APP_COOKIE" prop="appCookie" required>
        <el-input v-model="form.appCookie" placeholder="请输入APP_COOKIE" v-trim/>
      </el-form-item>
      <el-form-item label="Refresh_URL" prop="refreshUrl" required>
        <el-input v-model="form.refreshUrl" placeholder="请输入Refresh_URL" v-trim/>
      </el-form-item>
      <el-form-item label="推送用户ID" prop="wxUserId" required>
        <el-input v-model="form.wxUserId" placeholder="请输入需通知用户ID" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaTxVideoWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        pcCookie: '',
        appCookie: '',
        refreshUrl: '',
        wxUserId: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入用户名称' }
        ],
        pcCookie: [
          { required: true, message: '请输入PC_COOKIE' }
        ],
        appCookie: [
          { required: true, message: '请输入APP_COOKIE' }
        ],
        refreshUrl: [
          { required: true, message: '请输入Refresh_URL' }
        ],
        wxUserId: [
          { required: true, message: '请输入需通知用户ID' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/signing/txVideo',
      'field.id': 'id'
    })
  }
}
</script>
