import { render, staticRenderFns } from "./wxApplication.vue?vue&type=template&id=6aaff3d0&"
import script from "./wxApplication.vue?vue&type=script&lang=js&"
export * from "./wxApplication.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports