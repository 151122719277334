<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="名字" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入名字" v-trim/>
      </el-form-item>
      <el-form-item label="推送用户" prop="touser" required>
        <el-input v-model="form.touser" placeholder="请输入推送用户" v-trim/>
      </el-form-item>
      <el-form-item label="消息类型" prop="msgtype">
        <el-input v-model="form.msgtype" placeholder="请输入消息类型" v-trim/>
      </el-form-item>
      <el-form-item label="应用ID" prop="appId" required>
        <el-input v-model="form.appId" placeholder="请输入应用ID" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWxUserTempWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        touser: '',
        msgtype: '',
        appId: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入名字' }
        ],
        touser: [
          { required: true, message: '请输入推送用户' }
        ],
        appId: [
          { required: true, message: '请输入应用ID' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/wx/wxUserTemp',
      'field.id': 'id'
    })
  }
}
</script>
