<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="设备编号" prop="no" required>
        <el-input v-model="form.no" placeholder="请输入设备编号" v-trim/>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称" v-trim/>
      </el-form-item>
      <el-form-item label="品牌" prop="brand">
        <el-input v-model="form.brand" placeholder="请输入品牌" v-trim/>
      </el-form-item>
      <el-form-item label="型号" prop="model">
        <el-input v-model="form.model" placeholder="请输入型号" v-trim/>
      </el-form-item>
      <el-form-item label="设备类型" prop="type">
        <el-input v-model="form.type" placeholder="请输入设备类型" v-trim/>
      </el-form-item>
      <el-form-item label="IP/SN" prop="ipOrSn">
        <el-input v-model="form.ipOrSn" placeholder="IP/SN" v-trim/>
      </el-form-item>
      <el-form-item label="存放位置" prop="site">
        <el-input v-model="form.site" placeholder="请输入存放位置" v-trim/>
      </el-form-item>
      <el-form-item label="责任人" prop="personLiable">
        <el-input v-model="form.personLiable" placeholder="请输入责任人" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="form.remarks" placeholder="请输入备注" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaLylTableWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        no: '',
        name: '',
        brand: '',
        model: '',
        type: '',
        ipOrSn: '',
        site: '',
        personLiable: '',
        remarks: ''
      },
      // 验证规则
      rules: {
        no: [
          { required: true, message: '请输入设备编号' }
        ],
        name: [
          { required: true, message: '请输入名称' }
        ],
        site: [
          { required: true, message: '请输入存放位置' }
        ],
        personLiable: [
          { required: true, message: '请输入责任人' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/lylTable/lylTable',
      'field.id': 'id'
    })
  }
}
</script>
