<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="IP" prop="ip">
        <el-input v-model="form.ip" placeholder="请输入IP" v-trim/>
      </el-form-item>
      <el-form-item label="SN" prop="sn" required>
        <el-input v-model="form.sn" placeholder="请输入SN" v-trim/>
      </el-form-item>
      <el-form-item label="资产标签" prop="zcbq" required>
        <el-input v-model="form.zcbq" placeholder="请输入资产标签" v-trim/>
      </el-form-item>
      <el-form-item label="品牌" prop="brand" required>
        <el-input v-model="form.brand" placeholder="请输入品牌" v-trim/>
      </el-form-item>
      <el-form-item label="型号" prop="model" required>
        <el-input v-model="form.model" placeholder="请输入型号" v-trim/>
      </el-form-item>
      <el-form-item label="设备名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入设备名称" v-trim/>
      </el-form-item>
      <el-form-item label="事件" prop="event" required>
        <el-select v-model="form.event" placeholder="请选择事件" clearable>
          <el-option value="上架" label="上架"/>
          <el-option value="下架" label="下架"/>
          <el-option value="签收" label="签收"/>
          <el-option value="寄出" label="寄出"/>
        </el-select>
      </el-form-item>
      <el-form-item label="原因" prop="reason">
        <el-input v-model="form.reason" placeholder="请输入原因" v-trim/>
      </el-form-item>
      <el-form-item label="机房" prop="room">
        <el-select v-model="form.room" placeholder="请选择机房" clearable>
          <el-option :value="204" label="204"/>
          <el-option :value="305" label="305"/>
        </el-select>
      </el-form-item>
      <el-form-item label="机柜" prop="cabinet">
        <el-select v-model="form.cabinet" placeholder="请选择机柜" clearable>
          <el-option value="F07" label="F07"/>
          <el-option value="F08" label="F08"/>
          <el-option value="F09" label="F09"/>
          <el-option value="F10" label="F10"/>
          <el-option value="F11" label="F11"/>
          <el-option value="F12" label="F12"/>
          <el-option value="A09" label="A09"/>
          <el-option value="A10" label="A10"/>
          <el-option value="A12" label="A12"/>
        </el-select>
      </el-form-item>
      <el-form-item label="U位" prop="site">
        <el-input v-model="form.site" placeholder="请输入U位" v-trim/>
      </el-form-item>
      <el-form-item label="时间" prop="time" required>
        <el-date-picker v-model="form.time" value-format="yyyy-MM-dd" placeholder="请输入时间"></el-date-picker>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWorkAssetLogWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        ip: '',
        sn: '',
        zcbq: '',
        brand: '',
        model: '',
        name: '',
        event: '',
        reason: '',
        room: '',
        cabinet: '',
        site: '',
        time: ''
      },
      // 验证规则
      rules: {
        sn: [
          { required: true, message: '请输入SN' }
        ],
        brand: [
          { required: true, message: '请输入品牌' }
        ],
        model: [
          { required: true, message: '请输入型号' }
        ],
        event: [
          { required: true, message: '请输入事件' }
        ],
        time: [
          { required: true, message: '请选择时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/work/workAssetLog',
      'field.id': 'id'
    })
  }
}
</script>
