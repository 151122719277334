<template>
  <TableLayout :permissions="['work:work:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['work:work:create', 'work:work:delete']">
        <li><el-button type="primary" @click="$refs.operaWorkConfigWindow.open('新建')" icon="el-icon-plus" v-permissions="['work:work:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" min-width="100px"></el-table-column>
        <el-table-column prop="excelPrefix" label="Excel文件名前缀" min-width="100px"></el-table-column>
        <el-table-column prop="wordPrefix" label="Word文件名前缀" min-width="100px"></el-table-column>
        <el-table-column prop="excelTempFile" label="Excel模板文件" min-width="100px"></el-table-column>
        <el-table-column prop="wordTempFile" label="Word模板文件" min-width="100px"></el-table-column>
        <el-table-column prop="saveDir" label="保存目录" min-width="100px"></el-table-column>
        <el-table-column prop="logoFile" label="LOGO" min-width="100px"></el-table-column>
        <el-table-column prop="inspector" label="工作人员" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['work:work:update', 'work:work:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaWorkConfigWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['work:work:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWorkConfigWindow ref="operaWorkConfigWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaWorkConfigWindow from '@/components/work/OperaWorkConfigWindow'
export default {
  name: 'WorkConfig',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaWorkConfigWindow },
  created () {
    this.config({
      module: '',
      api: '/work/workConfig',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
