import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/lylTable/page', data, {
    trim: true
  })
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/lylTable/exportExcel', data, {
    trim: true,
    download: true
  })
}

// 创建
export function create (data) {
  return request.post('/lylTable/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/lylTable/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/lylTable/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/lylTable/delete/batch', {
    params: {
      ids
    }
  })
}

// 导出模板
export function exportTemp () {
  return request.post('/lylTable/exportXlsTemp', null, {
    responseType: 'blob',
    download: true
  })
}

// 上传文件
export function uploadFile (data) {
  const fd = new FormData()
  fd.append('file', data)
  return request.post('/lylTable/uploadXls', fd, {
    trim: true
  })
}