<template>
  <TableLayout :permissions="['work:work:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="类型" prop="type">
          <el-select v-model="searchForm.type" placeholder="请选择类型" clearable @change="search">
            <el-option value="服务器" label="服务器"/>
            <el-option value="防火墙" label="防火墙"/>
            <el-option value="交换机" label="交换机"/>
            <el-option value="STS" label="STS"/>
            <el-option value="其它设备" label="其它设备"/>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="searchForm.brand" placeholder="请输入品牌" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="model">
          <el-input v-model="searchForm.model" placeholder="请输入型号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="sn" prop="sn">
          <el-input v-model="searchForm.sn" placeholder="请输入sn" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="到货时间" prop="time">
          <el-date-picker v-model="searchForm.time" value-format="yyyy-MM-dd" placeholder="请输入到货时间" @change="search"/>
        </el-form-item>
        <el-form-item label="存放位置" prop="site">
          <el-input v-model="searchForm.site" placeholder="请输入存放位置" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['work:work:create', 'work:work:delete']">
        <li><el-button type="primary" @click="$refs.operaWorkInventoryWindow.open('新建')" icon="el-icon-plus" v-permissions="['work:work:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="type" label="类型" min-width="100px"></el-table-column>
        <el-table-column prop="brand" label="品牌" min-width="100px"></el-table-column>
        <el-table-column prop="model" label="型号" min-width="100px"></el-table-column>
        <el-table-column prop="sn" label="sn" min-width="100px"></el-table-column>
        <el-table-column prop="time" label="到货时间" min-width="100px"></el-table-column>
        <el-table-column prop="site" label="存放位置" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['work:work:update', 'work:work:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaWorkInventoryWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['work:work:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWorkInventoryWindow ref="operaWorkInventoryWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaWorkInventoryWindow from '@/components/work/OperaWorkInventoryWindow'
export default {
  name: 'WorkInventory',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaWorkInventoryWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        type: '',
        brand: '',
        model: '',
        sn: '',
        time: '',
        site: '',
        remark: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/work/workInventory',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
