<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="Excel文件名前缀" prop="excelPrefix" required>
        <el-input v-model="form.excelPrefix" placeholder="请输入Excel文件名前缀" v-trim/>
      </el-form-item>
      <el-form-item label="Word文件名前缀" prop="wordPrefix" required>
        <el-input v-model="form.wordPrefix" placeholder="请输入Word文件名前缀" v-trim/>
      </el-form-item>
      <el-form-item label="Excel模板文件" prop="excelTempFile" required>
        <el-input v-model="form.excelTempFile" placeholder="请输入Excel模板文件" v-trim/>
      </el-form-item>
      <el-form-item label="Word模板文件" prop="wordTempFile" required>
        <el-input v-model="form.wordTempFile" placeholder="请输入Word模板文件" v-trim/>
      </el-form-item>
      <el-form-item label="保存目录" prop="saveDir" required>
        <el-input v-model="form.saveDir" placeholder="请输入保存目录" v-trim/>
      </el-form-item>
      <el-form-item label="LOGO 文件" prop="logoFile" required>
        <el-input v-model="form.logoFile" placeholder="请输入LOGO 文件" v-trim/>
      </el-form-item>
      <el-form-item label="工作人员" prop="inspector" required>
        <el-input v-model="form.inspector" placeholder="请输入工作人员" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWorkConfigWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        excelPrefix: '',
        wordPrefix: '',
        excelTempFile: '',
        wordTempFile: '',
        saveDir: '',
        logoFile: '',
        inspector: ''
      },
      // 验证规则
      rules: {
        excelPrefix: [
          { required: true, message: '请输入Excel文件名前缀' }
        ],
        wordPrefix: [
          { required: true, message: '请输入Word文件名前缀' }
        ],
        excelTempFile: [
          { required: true, message: '请输入Excel模板文件' }
        ],
        wordTempFile: [
          { required: true, message: '请输入Word模板文件' }
        ],
        saveDir: [
          { required: true, message: '请输入保存目录' }
        ],
        logoFile: [
          { required: true, message: '请输入LOGO所在路径' }
        ],
        inspector: [
          { required: true, message: '请输入工作人员' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/work/workConfig',
      'field.id': 'id'
    })
  }
}
</script>
