<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="IP" prop="ip">
        <el-input v-model="form.ip" placeholder="请输入IP" v-trim/>
      </el-form-item>
      <el-form-item label="SN" prop="sn" required>
        <el-input v-model="form.sn" placeholder="请输入SN" v-trim/>
      </el-form-item>
      <el-form-item label="资产标签" prop="zcbq" required>
        <el-input v-model="form.zcbq" placeholder="请输入资产标签" v-trim/>
      </el-form-item>
      <el-form-item label="设备名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入设备名称" v-trim/>
      </el-form-item>
      <el-form-item label="机房" prop="room" required>
        <el-select v-model="form.room" placeholder="请选择机房" clearable>
          <el-option :value="204" label="204"/>
          <el-option :value="305" label="305"/>
        </el-select>
      </el-form-item>
      <el-form-item label="机柜" prop="cabinet" required>
        <el-select v-model="form.cabinet" placeholder="请选择机柜" clearable>
          <el-option value="F07" label="F07"/>
          <el-option value="F08" label="F08"/>
          <el-option value="F09" label="F09"/>
          <el-option value="F10" label="F10"/>
          <el-option value="F11" label="F11"/>
          <el-option value="F12" label="F12"/>
          <el-option value="A09" label="A09"/>
          <el-option value="A10" label="A10"/>
          <el-option value="A12" label="A12"/>
        </el-select>
      </el-form-item>
      <el-form-item label="U位" prop="site" required>
        <el-input v-model="form.site" placeholder="请输入U位" v-trim/>
      </el-form-item>
      <el-form-item label="上架时间" prop="time" required>
        <el-date-picker v-model="form.time" value-format="yyyy-MM-dd" placeholder="请输入时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="标签" prop="label">
        <el-input v-model="form.label" placeholder="请输入标签" v-trim/>
      </el-form-item>
      <el-form-item label="类型" prop="type" required>
        <el-select v-model="form.type" placeholder="请选择类型" clearable>
          <el-option value="服务器" label="服务器"/>
          <el-option value="防火墙" label="防火墙"/>
          <el-option value="交换机" label="交换机"/>
          <el-option value="STS" label="STS"/>
          <el-option value="其它设备" label="其它设备"/>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌" prop="brand">
        <el-input v-model="form.brand" placeholder="请输入品牌" v-trim/>
      </el-form-item>
      <el-form-item label="型号" prop="model">
        <el-input v-model="form.model" placeholder="请输入型号" v-trim/>
      </el-form-item>
      <el-form-item label="所属人" prop="personLiable">
        <el-input v-model="form.personLiable" placeholder="请输入所属人" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWorkAssetManagementWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        ip: '',
        sn: '',
        zcbq: '',
        name: '',
        room: '',
        cabinet: '',
        site: '',
        label: '',
        type: '',
        brand: '',
        model: '',
        personLiable: '',
        time: ''
      },
      // 验证规则
      rules: {
        sn: [
          { required: true, message: '请输入SN' }
        ],
        room: [
          { required: true, message: '请输入机房' }
        ],
        cabinet: [
          { required: true, message: '请输入机柜' }
        ],
        site: [
          { required: true, message: '请输入U位' }
        ],
        type: [
          { required: true, message: '请输入类型' }
        ],
        time: [
          { required: true, message: '请选择时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/work/workAssetManagement',
      'field.id': 'id'
    })
  }
}
</script>
