<template>
  <TableLayout :permissions="['work:work:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="类型" prop="type">
          <el-select v-model="searchForm.type" placeholder="请选择类型" clearable @change="search">
            <el-option value="其它" label="其它"/>
            <el-option value="变更" label="变更"/>
            <el-option value="事件" label="事件"/>
            <el-option value="问题" label="问题"/>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="time">
          <el-date-picker v-model="searchForm.time" value-format="yyyy-MM-dd" placeholder="请输入时间" @change="search"/>
        </el-form-item>
        <el-form-item label="内容" prop="data">
          <el-input v-model="searchForm.data" placeholder="请输入内容" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="searchForm.remark" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['work:work:create', 'work:work:delete']">
        <li><el-button type="primary" @click="$refs.operaWorkLogWindow.open('新建')" icon="el-icon-plus" v-permissions="['work:work:create']">新建</el-button></li>
        <li><el-button type="primary" @click="$refs.OperaWorkLogCreatReportWindow.open('生成日报')" icon="el-icon-plus" v-permissions="['work:work:exportExcel']">生成日报</el-button></li>
        <li><el-button type="primary" @click="exportExcel" icon="el-icon-download" v-permissions="['work:work:exportExcel']">导出</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort = "{prop: 'time', order: 'descending'}"
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="type" label="类型" min-width="50px"></el-table-column>
        <el-table-column prop="time" label="时间" min-width="80px" sortable="custom" sort-by="time"></el-table-column>
        <el-table-column prop="data" label="内容" min-width="100px"></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['work:work:update', 'work:work:delete'])"
          label="操作"
          min-width="120"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaWorkLogWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['work:work:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWorkLogWindow ref="operaWorkLogWindow" @success="handlePageChange"/>
    <!-- 生成报告，新加 -->
    <OperaWorkLogCreatReportWindow ref="OperaWorkLogCreatReportWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaWorkLogWindow from '@/components/work/OperaWorkLogWindow'
// 加入时间选择，新加
import OperaWorkLogCreatReportWindow from '@/components/work/OperaWorkLogCreatReportWindow'
export default {
  name: 'WorkLog',
  extends: BaseTable,
  // 最后一个参数，新加
  components: { SearchFormCollapse, TableLayout, Pagination, OperaWorkLogWindow, OperaWorkLogCreatReportWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        type: '',
        time: '',
        data: '',
        remark: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/work/workLog',
      'field.id': 'id',
      'field.main': 'id',
      sorts: [{
        property: 'time',
        direction: 'DESC'
      }]
    })
    this.search()
  }
}
</script>
