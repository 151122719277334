<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="应用名" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入应用名" v-trim/>
      </el-form-item>
      <el-form-item label="应用ID" prop="agentId" required>
        <el-input v-model="form.agentId" placeholder="请输入应用ID" v-trim/>
      </el-form-item>
      <el-form-item label="应用Secret" prop="secret" required>
        <el-input v-model="form.secret" placeholder="请输入应用Secret" v-trim/>
      </el-form-item>
      <el-form-item label="企业ID" prop="companyId" required>
        <el-input v-model="form.companyId" placeholder="请输入企业ID" v-trim/>
      </el-form-item>
      <el-form-item label="ACCESS_TOKEN" prop="accessToken">
        <el-input v-model="form.accessToken" placeholder="请输入ACCESS_TOKEN" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWxApplicationWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        agentId: '',
        secret: '',
        companyId: '',
        accessToken: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入应用名' }
        ],
        agentId: [
          { required: true, message: '请输入应用ID' }
        ],
        secret: [
          { required: true, message: '请输入应用Secret' }
        ],
        companyId: [
          { required: true, message: '请输入企业ID' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/wx/wxApplication',
      'field.id': 'id'
    })
  }
}
</script>
