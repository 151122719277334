<template>
  <TableLayout :permissions="['work:work:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="事件" prop="event">
          <el-select v-model="searchForm.event" placeholder="请选择事件" clearable @change="search">
            <el-option value="上架" label="上架"/>
            <el-option value="下架" label="下架"/>
            <el-option value="签收" label="签收"/>
            <el-option value="寄出" label="寄出"/>
          </el-select>
        </el-form-item>
        <el-form-item label="IP" prop="ip">
          <el-input v-model="searchForm.ip" placeholder="请输入IP" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="SN" prop="sn">
          <el-input v-model="searchForm.sn" placeholder="请输入SN" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="机房" prop="room">
          <el-select v-model="searchForm.room" placeholder="请选择机房" clearable @change="search">
            <el-option :value="204" label="204"/>
            <el-option :value="305" label="305"/>
          </el-select>
        </el-form-item>
        <el-form-item label="机柜" prop="cabinet">
          <el-select v-model="searchForm.cabinet" placeholder="请选择机柜" clearable @change="search">
            <el-option value="F07" label="F07"/>
            <el-option value="F08" label="F08"/>
            <el-option value="F09" label="F09"/>
            <el-option value="F10" label="F10"/>
            <el-option value="F11" label="F11"/>
            <el-option value="F12" label="F12"/>
            <el-option value="A09" label="A09"/>
            <el-option value="A10" label="A10"/>
            <el-option value="A12" label="A12"/>
          </el-select>
        </el-form-item>
        <el-form-item label="U位" prop="site">
          <el-input v-model="searchForm.site" placeholder="请输入U位" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="资产标签" prop="zcbq">
          <el-input v-model="searchForm.zcbq" placeholder="请输入资产标签" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="searchForm.brand" placeholder="请输入品牌" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="model">
          <el-input v-model="searchForm.model" placeholder="请输入型号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入设备名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input v-model="searchForm.reason" placeholder="请输入原因" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['work:work:create', 'work:work:delete']">
        <li><el-button type="primary" @click="$refs.operaWorkAssetLogWindow.open('新建')" icon="el-icon-plus" v-permissions="['work:work:create']">新建</el-button></li>
        <li><el-button type="primary" @click="exportExcel" icon="el-icon-download" v-permissions="['work:work:exportExcel']">导出</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort = "{prop: 'time', order: 'descending'}"
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="ip" label="IP" min-width="100px"></el-table-column>
        <el-table-column prop="sn" label="SN" min-width="100px"></el-table-column>
        <el-table-column prop="zcbq" label="资产标签" min-width="100px"></el-table-column>
        <el-table-column prop="brand" label="品牌" min-width="100px"></el-table-column>
        <el-table-column prop="model" label="型号" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="设备名称" min-width="100px"></el-table-column>
        <el-table-column prop="event" label="事件" min-width="100px"></el-table-column>
        <el-table-column prop="reason" label="原因" min-width="100px"></el-table-column>
        <el-table-column prop="room" label="机房" min-width="60px"></el-table-column>
        <el-table-column prop="cabinet" label="机柜" min-width="60px"></el-table-column>
        <el-table-column prop="site" label="U位" min-width="60px"></el-table-column>
        <el-table-column prop="time" label="时间" min-width="80px" sortable="custom" sort-by="time"></el-table-column>
        <el-table-column
          v-if="containPermissions(['work:work:update', 'work:work:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaWorkAssetLogWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['work:work:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['work:work:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWorkAssetLogWindow ref="operaWorkAssetLogWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaWorkAssetLogWindow from '@/components/work/OperaWorkAssetLogWindow'
export default {
  name: 'WorkAssetLog',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaWorkAssetLogWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        ip: '',
        sn: '',
        zcbq: '',
        brand: '',
        model: '',
        name: '',
        event: '',
        reason: '',
        room: '',
        cabinet: '',
        site: '',
        time: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/work/workAssetLog',
      'field.id': 'id',
      'field.main': 'sn',
      sorts: [{
        property: 'time',
        direction: 'DESC'
      }]
    })
    this.search()
  }
}
</script>
