<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="开始时间" prop="startTime" required>
        <el-date-picker v-model="form.startTime" value-format="yyyy-MM-dd" placeholder="请输入时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="停止时间" prop="endTime" required>
        <el-date-picker v-model="form.endTime" value-format="yyyy-MM-dd" placeholder="请输入时间"></el-date-picker>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWorkLogCreatReportWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        startTime: '',
        endTime: ''
      },
      // 验证规则
      rules: {
        startTime: [
          { required: true, message: '请选择时间' }
        ],
        endTime: [
          { required: true, message: '请选择时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/work/workLog',
      'field.id': 'id'
    })
  },
  methods: {
    // 确认生成报告
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        // 调用新建接口
        this.isWorking = true
        this.api.creatReport(this.form)
          .then(() => {
            this.visible = false
            this.$tip.apiSuccess('生成成功')
            this.$emit('success')
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
            this.isWorking = false
          })
      })
    }
  }
}
</script>
