<template>
  <TableLayout :permissions="['signing:txVideo:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="ID" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入用户名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="PC_COOKIE" prop="pcCookie">
          <el-input v-model="searchForm.pcCookie" placeholder="请输入PC_COOKIE" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="APP_COOKIE" prop="appCookie">
          <el-input v-model="searchForm.appCookie" placeholder="请输入APP_COOKIE" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="Refresh_URL" prop="refreshUrl">
          <el-input v-model="searchForm.refreshUrl" placeholder="请输入Refresh_URL" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="推送用户ID" prop="wxUserId">
          <el-input v-model="searchForm.wxUserId" placeholder="请输入需通知用户ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['signing:txVideo:create', 'signing:txVideo:delete']">
        <li><el-button type="primary" @click="$refs.operaTxVideoWindow.open('新建')" icon="el-icon-plus" v-permissions="['signing:txVideo:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['signing:txVideo:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="用户名" min-width="100px"></el-table-column>
        <el-table-column prop="pcCookie" label="PC_COOKIE" min-width="100px"></el-table-column>
        <el-table-column prop="appCookie" label="APP_COOKIE" min-width="100px"></el-table-column>
        <el-table-column prop="refreshUrl" label="Refresh_URL" min-width="100px"></el-table-column>
        <el-table-column prop="wxUserId" label="推送用户ID" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['signing:txVideo:update', 'signing:txVideo:delete', 'signing:txVideo:sign'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaTxVideoWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['signing:txVideo:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['signing:txVideo:delete']">删除</el-button>
            <el-button type="text" @click="txVideoSignById(row)" icon="el-icon-position" v-permissions="['signing:txVideo:sign']">签到</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaTxVideoWindow ref="operaTxVideoWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/signing/base/SignTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaTxVideoWindow from '@/components/signing/OperaTxVideoWindow'
export default {
  name: 'TxVideo',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaTxVideoWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        name: '',
        pcCookie: '',
        appCookie: '',
        refreshUrl: '',
        wxUserId: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/signing/txVideo',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
