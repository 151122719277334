<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="类型" prop="type" required>
        <el-select v-model="form.type" placeholder="请选择类型" clearable>
          <el-option value="其它" label="其它"/>
          <el-option value="变更" label="变更"/>
          <el-option value="事件" label="事件"/>
          <el-option value="问题" label="问题"/>
        </el-select>
      </el-form-item>
      <el-form-item label="时间" prop="time" required>
        <el-date-picker v-model="form.time" value-format="yyyy-MM-dd" placeholder="请输入时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="内容" prop="data" required>
        <el-input v-model="form.data" placeholder="请输入内容" v-trim/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaWorkLogWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        type: '',
        time: '',
        data: '',
        remark: ''
      },
      // 验证规则
      rules: {
        type: [
          { required: true, message: '请输入类型' }
        ],
        time: [
          { required: true, message: '请选择时间' }
        ],
        data: [
          { required: true, message: '请输入内容' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/work/workLog',
      'field.id': 'id'
    })
  }
}
</script>
