<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="标题" prop="title" required>
        <el-input v-model="form.title" placeholder="请输入消息标题" v-trim/>
      </el-form-item>
      <el-form-item label="内容" prop="msg" required>
        <el-input v-model="form.msg" placeholder="请输入消息正文" v-trim/>
      </el-form-item>
      <el-form-item label="URL" prop="url">
        <el-input v-model="form.url" placeholder="请输入消息详情链接" v-trim/>
      </el-form-item>
      <el-form-item label="按钮名称" prop="btntxt">
        <el-input v-model="form.btntxt" placeholder="请输入按钮名称" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/wx/base/PushOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'WxPushWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        title: '',
        msg: '',
        url: '',
        btntxt: ''
      },
      // 验证规则
      rules: {
        title: [
          { required: true, message: '请输入消息标题' }
        ],
        msg: [
          { required: true, message: '请输入消息正文' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/wx/wxUserTemp',
      'field.id': 'id'
    })
  }
}
</script>
