<template>
  <TableLayout :permissions="['lyl:lyltable:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="设备编号" prop="no">
          <el-input v-model="searchForm.no" placeholder="请输入设备编号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="brand">
          <el-input v-model="searchForm.brand" placeholder="请输入品牌" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="型号" prop="model">
          <el-input v-model="searchForm.model" placeholder="请输入型号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="type">
          <el-input v-model="searchForm.type" placeholder="设备类型" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="IP/SN" prop="ipOrSn">
          <el-input v-model="searchForm.ipOrSn" placeholder="IP/SN" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="存放位置" prop="site">
          <el-input v-model="searchForm.site" placeholder="请输入存放位置" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="责任人" prop="personLiable">
          <el-input v-model="searchForm.personLiable" placeholder="请输入责任人" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="searchForm.remarks" placeholder="请输入备注" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['lyl:lyltable:create', 'lyl:lyltable:delete']">
        <li><el-button type="primary" @click="$refs.operaLylTableWindow.open('新建')" icon="el-icon-plus" v-permissions="['lyl:lyltable:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['lyl:lyltable:delete']">删除</el-button></li>
        <li><el-upload action="#"  :http-request="uploadExcel" accept=".xls" ref="upload"><el-button size="small" icon="el-icon-upload" type="primary">批量导入</el-button></el-upload></li>
        <li><el-button type="primary" icon="el-icon-download" @click="exportTemp">下载批量导入模板</el-button></li>
        <li><el-button type="primary" @click="exportExcel" icon="el-icon-download" v-permissions="['lyl:lyltable:exportExcel']">导出</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        :default-sort = "{prop: 'no', order: 'descending'}"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="no" label="设备编号" sortable="custom" sort-by="no" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="100px"></el-table-column>
        <el-table-column prop="brand" label="品牌" min-width="100px"></el-table-column>
        <el-table-column prop="model" label="型号" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="设备类型" min-width="100px"></el-table-column>
        <el-table-column prop="ipOrSn" label="IP/SN" min-width="100px"></el-table-column>
        <el-table-column prop="site" label="存放位置" min-width="100px"></el-table-column>
        <el-table-column prop="personLiable" label="责任人" min-width="100px"></el-table-column>
        <el-table-column prop="remarks" label="备注" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['lyl:lyltable:update', 'lyl:lyltable:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaLylTableWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['lyl:lyltable:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['lyl:lyltable:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaLylTableWindow ref="operaLylTableWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/lylTable/base/LylBaseTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaLylTableWindow from '@/components/lylTable/OperaLylTableWindow'
export default {
  name: 'LylTable',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaLylTableWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        no: '',
        name: '',
        brand: '',
        model: '',
        site: '',
        personLiable: '',
        type: '',
        remarks: '',
        ipOrSn: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/lylTable/lylTable',
      'field.id': 'id',
      'field.main': 'id',
      sorts: [{
        property: 'no',
        direction: 'DESC'
      }]
    })
    this.search()
  }
}
</script>
