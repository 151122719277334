<template>
  <TableLayout :permissions="['wx:wxApplication:query']">
    <!-- 搜索表单 -->
    <SearchFormCollapse slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="ID" prop="id">
          <el-input v-model="searchForm.id" placeholder="请输入ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="应用名" prop="name">
          <el-input v-model="searchForm.name" placeholder="请输入应用名" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="应用ID" prop="agentId">
          <el-input v-model="searchForm.agentId" placeholder="请输入应用ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="应用Secret" prop="secret">
          <el-input v-model="searchForm.secret" placeholder="请输入应用Secret" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="企业ID" prop="companyId">
          <el-input v-model="searchForm.companyId" placeholder="请输入企业ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="ACCESS_TOKEN" prop="accessToken">
          <el-input v-model="searchForm.accessToken" placeholder="请输入ACCESS_TOKEN" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['wx:wxApplication:create', 'wx:wxApplication:delete']">
        <li><el-button type="primary" @click="$refs.operaWxApplicationWindow.open('新建')" icon="el-icon-plus" v-permissions="['wx:wxApplication:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['wx:wxApplication:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID" min-width="100px"></el-table-column>
        <el-table-column prop="name" label="应用名" min-width="100px"></el-table-column>
        <el-table-column prop="agentId" label="应用ID" min-width="100px"></el-table-column>
        <el-table-column prop="secret" label="应用Secret" min-width="100px"></el-table-column>
        <el-table-column prop="companyId" label="企业ID" min-width="100px"></el-table-column>
        <el-table-column prop="accessToken" label="ACCESS_TOKEN" min-width="100px"></el-table-column>
        <el-table-column prop="updateTime" label="TOKEN更新时间" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['wx:wxApplication:update', 'wx:wxApplication:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaWxApplicationWindow.open('编辑', row)" icon="el-icon-edit" v-permissions="['wx:wxApplication:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['wx:wxApplication:delete']">删除</el-button>
            <el-button type="text" @click="updateTokenById(row)" icon="el-icon-position" v-permissions="['wx:wxApplication:update']">更新token</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaWxApplicationWindow ref="operaWxApplicationWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/wx/base/WxTokenTable'
import TableLayout from '@/layouts/TableLayout'
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import Pagination from '@/components/common/Pagination'
import OperaWxApplicationWindow from '@/components/wx/OperaWxApplicationWindow'
export default {
  name: 'WxApplication',
  extends: BaseTable,
  components: { SearchFormCollapse, TableLayout, Pagination, OperaWxApplicationWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        name: '',
        agentId: '',
        secret: '',
        companyId: '',
        accessToken: ''
      }
    }
  },
  created () {
    this.config({
      module: '',
      api: '/wx/wxApplication',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
